import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  toggleButtonGroupClasses,
  toggleButtonClasses,
  Typography,
} from '@mui/material';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  width: '100%',
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
  },
  [`.${toggleButtonClasses.root}`]: {
    width: '50%',
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  [`.${toggleButtonClasses.root}.${toggleButtonClasses.selected}, .${toggleButtonClasses.root}.${toggleButtonClasses.selected}:hover`]:
    {
      backgroundColor: theme.palette.common.white,
    },
}));

const TermSegmentedControl = () => {
  const [term, setTerm] = React.useState('Monthly');
  return (
    <Box title={`${term} is selected`}>
      <Paper
        elevation={0}
        sx={(theme) => ({
          display: 'flex',
          border: `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        })}
      >
        <StyledToggleButtonGroup value={term} onChange={(_, newTerm) => setTerm(newTerm)} exclusive>
          <ToggleButton value="Monthly">Monthly</ToggleButton>
          <ToggleButton value="Annually">Annually</ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
      <Box display="flex">
        <Typography
          width="50%"
          sx={(theme) => ({
            color: theme.palette.portalTheme.termOfferTextColor,
            fontSize: '0.85rem',
            '@media (min-width: 1200px)': {
              fontSize: '1rem',
            },
          })}
        ></Typography>
        <Typography
          width="50%"
          textAlign="center"
          p="0.5rem"
          sx={(theme) => ({
            color: theme.palette.portalTheme.termOfferTextColor,
            fontSize: '0.85rem',
            '@media (min-width: 1200px)': {
              fontSize: '1rem',
            },
          })}
        >
          Annual offer available
        </Typography>
      </Box>
    </Box>
  );
};

export default TermSegmentedControl;
