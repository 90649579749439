import { useQuery } from '@tanstack/react-query';
import useAccount from './useAccount';
import { getCookie } from '@utils/cookies';
import { useSelector } from 'react-redux';
import { TokenTypes } from '@api/services/StoreService';
import { RootState } from '@app/reducers';
import { AgreementsRequest } from '@cv/portal-cps-lib/agreements/agreement-service/models';
import useVehicle from './useVehicle';
import { getAgreements } from '@cv/portal-cps-lib';
import { useConfig } from '@components/ConfigProvider';

const useTNC = () => {
  const accessToken = getCookie(TokenTypes.ACCESS_TOKEN) ?? '';
  const locale = useSelector(({ settingsReducer }: RootState) => settingsReducer?.locale || 'en-US');
  const config = useConfig();
  const environment = config.getEnvironmentEnum();

  const { data: account } = useAccount();
  const { data: vehicle } = useVehicle();
  const userId = account?._id;
  const vin = vehicle?.vin;

  return useQuery({
    enabled: !!(userId && vin && accessToken),
    queryKey: ['tncAgreement', vin],
    queryFn: async () => {
      const request: AgreementsRequest = {
        data: { userId, vin },
        environment: environment,
        headers: {
          Authorization: accessToken,
          'Accept-Language': locale,
        },
      };
      const response = await getAgreements(request);
      const agreement = response.data.payload.find((details) => details.vin === vin);

      return agreement;
    },
  });
};

export default useTNC;
