import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import TermSegmentedControl from '@components/TermSegmentedControl';
import SelectedTotal from '@components/SelectedTotal';
import PackagesSelect from '@components/PackagesSelect';

const SelectPaidPackages = () => {
  return (
    <Box
      sx={{
        margin: '0 auto',
        padding: '0 1rem',
        width: '100%',
        minWidth: '315px',
        '@media (min-width: 768px)': {
          maxWidth: '750px',
        },
        '@media (min-width: 992px)': {
          maxWidth: '970px',
        },
        '@media (min-width: 1200px)': {
          maxWidth: '1170px',
        },
      }}
    >
      <Stack
        justifyContent="space-between"
        sx={{
          marginTop: '0.5rem',
          '@media (min-width: 1200px)': {
            flexDirection: 'row',
          },
        }}
      >
        <Box
          sx={(theme) => ({
            width: '100%',
            backgroundColor: theme.palette.grey[200],
            paddingBottom: '1rem',
            '@media (min-width: 1200px)': {
              width: '50%',
              padding: '5rem',
            },
          })}
        >
          <Typography
            component="h2"
            fontSize="1.75rem"
            fontWeight="700"
            mb="2rem"
            sx={{
              display: 'none',
              '@media (min-width: 1200px)': {
                display: 'block',
              },
            }}
          >
            Available Packages
          </Typography>
          <PackagesSelect />
        </Box>
        <Box
          sx={(theme) => ({
            width: '100%',
            borderTop: `1px solid ${theme.palette.common.black}`,
            paddingTop: '1rem',
            '@media (min-width: 1200px)': {
              borderTopWidth: 0,
              width: '50%',
              padding: '15rem 5rem',
            },
          })}
        >
          <TermSegmentedControl />
          <SelectedTotal onContinue={() => console.log('continue')} />
        </Box>
      </Stack>
    </Box>
  );
};

export default SelectPaidPackages;
