import React from 'react';
import { formatAnalyticsEventName } from '@cv/portal-common-lib';
import styles from './AgreementLink.module.css';
import { Link } from '@mui/material';
import useTNC from '@api/queries/useTNC';

type PortalTextLinkProps = {
  name: string;
  linkText: string;
  regularText: string;
  textAlignment?: 'left' | 'right' | 'center' | 'justify';
  analyticsEventName?: string;
};

function AgreementLink({ linkText, regularText, textAlignment, analyticsEventName }: PortalTextLinkProps) {
  const formatEventName = formatAnalyticsEventName(analyticsEventName);
  const { data: agreement, isFetching } = useTNC();

  const openAgreement = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const win = window.open(agreement?.agreementUrl, '_blank');
    win?.focus();
  };

  if (!agreement?.agreementUrl || isFetching) {
    return null;
  }

  return (
    <div className={`${styles.AgreementLinkContainer} text-${textAlignment}`}>
      {regularText + ' '}
      <Link component="button" onClick={openAgreement} data-analyticseventname={formatEventName}>
        {linkText}
      </Link>
    </div>
  );
}

export default AgreementLink;
