export enum TenantIds {
  ABC_Car_Comp = 'abccar1',
  Fca = 'fca',
  Infiniti_MX = 'infinitimx',
  Nissan_MX = 'nissanmx',
  Nissan_US = 'nissanus',
  Nissan_CA = 'nissanca',
  Infiniti_US = 'infinitius',
  Infiniti_CA = 'infinitica',
  Toyota = 'toyota',
  Lexus = 'lexus',
  Honda = 'honda',
  Acura = 'acura',
  Subaru = 'subaru',
  Future_Tenant = 'futuretenant',
  Msafety = 'msafety',
}

export const TenantsSupportOnlyUpgrade = {
  [TenantIds.Future_Tenant]: TenantIds.Future_Tenant,
};
