import React, { useMemo } from 'react';
import { createTheme, getContrastRatio, PaletteOptions, ThemeProvider as MuiThemeProvider } from '@mui/material';

export interface CustomTheme {
  primaryColor: string;
  primaryHoverColor: string;
  headerBackgroundColor: string;
  subHeaderBackgroundColor: string;
  buttonRadius: number;
  buttonBorderWidth: number;
  secondaryColor: string;
  backgroundColor: string;
  errorColor: string;
  navigationTextColor: string;
  textColor: string;
  secondaryTextColor: string;
  cardBackgroundColor: string;
  footerTextColor: string;
  footerBackgroundColor: string;
  navigationTextActive?: string;
  termOfferTextColor: string;
}

declare module '@mui/material/styles' {
  interface TypeBackground {
    header: string;
    subHeader: string;
    footer: string;
  }

  interface TypeText {
    navigation: string;
    footer: string;
    headerIcons: string;
    navigationActive: string;
  }

  interface Palette {
    portalTheme: Partial<CustomTheme>;
  }
  interface PaletteOptions {
    portalTheme: Partial<CustomTheme>;
  }
}

// should be removed after we remove styled-components
declare module 'styled-components' {
  export interface DefaultTheme extends CustomTheme {}
}

const ThemeProvider: React.FC<{ theme: CustomTheme; children: React.ReactNode }> = ({ theme, children }) => {
  const mappedPalette: PaletteOptions = { portalTheme: { ...theme } };
  const {
    primaryColor,
    primaryHoverColor,
    secondaryColor,
    errorColor,
    buttonRadius = 6,
    backgroundColor,
    navigationTextColor,
    textColor,
    secondaryTextColor,
    cardBackgroundColor,
    buttonBorderWidth = 1,
    footerTextColor,
    headerBackgroundColor,
    subHeaderBackgroundColor,
    footerBackgroundColor,
    navigationTextActive,
  } = theme;

  const btnBWidth = buttonBorderWidth + 'px';
  if (primaryColor) {
    mappedPalette.primary = {
      main: primaryColor,
      ...(primaryHoverColor && { dark: primaryHoverColor }),
    };
    mappedPalette.action = { disabledOpacity: 0.4, disabled: 'rgba(255, 255, 255, 0.5)' };
    mappedPalette.background = {
      header: headerBackgroundColor,
      subHeader: subHeaderBackgroundColor,
      default: backgroundColor,
      paper: cardBackgroundColor,
      footer: footerBackgroundColor,
    };
    mappedPalette.text = {
      navigation: navigationTextColor,
      primary: textColor,
      secondary: secondaryTextColor,
      footer: footerTextColor,
      headerIcons: getContrastRatio(primaryColor, headerBackgroundColor) > 2 ? primaryColor : '#fff',
      navigationActive: navigationTextActive || primaryColor,
    };

    secondaryColor && (mappedPalette.secondary = { main: secondaryColor });
    errorColor && (mappedPalette.error = { main: errorColor });
  }

  const mappedTheme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: 'var(--theme-font-family)',
          allVariants: {
            color: mappedPalette?.text?.primary,
          },
        },
        palette: { ...mappedPalette, contrastThreshold: 2 },
        components: {
          MuiTypography: {
            variants: [
              {
                props: { variant: 'h6' },
                style: {
                  fontSize: '1rem',
                },
              },
            ],
          },
          MuiCssBaseline: {
            styleOverrides: `
                        * {
                            box-sizing: border-box;
                            margin: 0;
                            padding: 0;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                        }
                      `,
          },
          MuiCheckbox: {
            styleOverrides: {
              root: () => ({
                svg: {
                  border: '2px solid black',
                  borderRadius: '3px',
                  width: '15px',
                  height: '15px',
                  fill: 'none',
                  stroke: primaryColor,
                  strokeWidth: '3.5px',
                },
                '&.Mui-disabled svg': {
                  stroke: 'gray',
                },
              }),
            },
          },
          MuiLink: {
            styleOverrides: {
              root: ({ theme }) => ({
                color: primaryColor,
                textDecoration: 'none',
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline',
                  color: theme.palette.primary.dark,
                },
              }),
            },
          },
          MuiButton: {
            variants: [
              {
                props: { variant: 'contained' },
                style: {
                  color: 'white',
                },
              },
            ],
            defaultProps: {
              disableElevation: true,
              size: 'medium',
            },
            styleOverrides: {
              root: ({ theme }) => ({
                color: theme.palette.primary.main,
                fontFamily: 'inherit',
                textTransform: 'none',
                borderRadius: buttonRadius,
                //Border for making contained and outlined buttons same height because of overrides paddings
                border: `${btnBWidth} solid ${theme.palette.primary.main}`,

                '@media screen and (max-width: 425px)': {
                  fontSize: '1.2rem',
                },
                '&:hover': {
                  border: `${btnBWidth} solid ${theme.palette.primary.dark}`,
                },
                '&.Mui-disabled': {
                  opacity: theme.palette.action.disabledOpacity,
                },
              }),
              contained: ({ theme }) => ({
                padding: '5px 15px',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
                '&.Mui-disabled': {
                  color: theme.palette.action.disabled,
                  backgroundColor: theme.palette.primary.main,
                },
              }),
              outlined: ({ theme }) => ({
                color: theme.palette.primary.main,
                '&:hover': {
                  color: theme.palette.primary.dark,
                },
                '&.Mui-disabled': {
                  color: theme.palette.primary.main,
                  borderColor: theme.palette.primary.main,
                  borderWidth: btnBWidth,
                },
              }),
              text: ({ theme }) => ({
                disableRipple: true,
                padding: 0,
                borderColor: 'transparent',
                '&:hover': {
                  borderColor: 'transparent',
                  backgroundColor: 'transparent',
                  textDecoration: 'underline',
                  color: theme.palette.primary.dark,
                },
                '&.Mui-disabled': {
                  color: theme.palette.primary.main,
                },
                '@media screen and (max-width: 425px)': {
                  fontSize: '1rem',
                },
              }),
              sizeMedium: {
                fontSize: '1rem',
              },
              sizeLarge: {
                fontSize: '1.5rem',
              },
            },
          },
          MuiTab: {
            styleOverrides: {
              root: {
                backgroundColor: '#dadada',
                '&.Mui-selected': {
                  backgroundColor: 'white',
                },
              },
            },
          },
          MuiTabs: {
            styleOverrides: {
              root: {
                '.MuiTabs-indicator': {
                  top: 0,
                },
              },
            },
          },
          MuiRadio: {
            styleOverrides: {
              root: {
                padding: '0',
                marginRight: '8px',
                color: primaryColor || '#000',
                '&.Mui-checked': {
                  color: primaryColor || '#000',
                },
                '&&:hover': {
                  backgroundColor: 'transparent',
                },
              },
            },
          },
        },
      }),
    [theme],
  );
  return <MuiThemeProvider theme={mappedTheme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
