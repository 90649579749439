import React from 'react';
import clsx from 'clsx';
import {
  SubscribedPackage,
  Product,
} from '@cv/portal-cps-lib/subscription/subscription-management/models/package-subscription';
import { VehicleServiceDetails } from '@cv/portal-cps-lib/vehicle/vehicle-management/models';
import { ContentfulFile } from '@customTypes/ContentfulComponent';
import { FaChevronRight } from 'react-icons/fa';
import { Box } from '@mui/material';
import { useHistory } from 'react-router';

import Dropdown from '@components/Dropdown';
import Button from '@components-lib/components/Button';
import dropDownStyle from '@components/Dropdown/Dropdown.module.css';
import PackageItem from './PackageItem';
import s from './SubscriptionInformation.module.css';
import { orderPackages } from '@utils/orderPackages';
import isPackageToShow from '@utils/isPackageToShow';
import useSubscribedPackages from '@api/queries/useSubscribedPackages';
import Spinner from '@app/components-lib/components/Spinner';

export interface ProductWithServices extends Product {
  services?: VehicleServiceDetails[];
}
export interface SubscribedPackageWithServices extends SubscribedPackage {
  packageName: string;
  products: ProductWithServices[];
}

export type PackageItemContenfulProps = {
  trialSubscriptionLabel: string;
  paidSubscriptionLabel: string;
  endsLabel: string;
  startsLabel: string;
  renewsLabel: string;
  beginsLabel: string;
  activeIcon?: ContentfulFile;
  upcomingIcon?: ContentfulFile;
  infoIcon?: ContentfulFile;
  productIcon?: ContentfulFile;
  renewsMonthly: string;
  renewsAnnually: string;
  renewsTermAnuall: string;
  renewsTermMouth: string;
  endOfTermLabel?: string;
  promoTermLabel?: string;
  currencyLabels: { usd: string; cad: string; mxn: string };
};

export type SubscriptionInformationProps = PackageItemContenfulProps & {
  name: string;
  title: string;
  dropdownTitleHasPackages: string;
  dropdownTitleNoPackages: string;
  addSubscriptionLabel: string;
  managePackageLink: string;
  className?: string;
  theme?: string;
  dropDownContainer?: HTMLElement;
};

const SubscriptionInformation = (props: SubscriptionInformationProps) => {
  const {
    name,
    title,
    dropdownTitleHasPackages,
    dropdownTitleNoPackages,
    managePackageLink,
    addSubscriptionLabel,
    dropDownContainer,
    className = '',
    theme,
    ...packageItemProps
  } = props;
  const { isLoading, data } = useSubscribedPackages();
  const history = useHistory();

  const packagesToShow = (data?.packages || []).filter((pkg) => isPackageToShow(pkg));

  const showAddButton = !packagesToShow?.find((p) => p.autoRenew || new Date(p.termStartDate) > new Date(Date.now()));

  if (isLoading) {
    return <Spinner />;
  }

  if (!packagesToShow?.length) {
    return (
      <div className={clsx(dropDownStyle['Dropdown'], { [dropDownStyle.dark]: theme === 'dark' })}>
        <button className={clsx(dropDownStyle['Dropdown-button'])} onClick={() => history.push(managePackageLink)}>
          {dropdownTitleNoPackages}
          <FaChevronRight className={dropDownStyle['Dropdown-chevron']} />
        </button>
      </div>
    );
  }
  return (
    <Dropdown
      firstItemLabel={dropdownTitleHasPackages}
      theme={theme}
      classes={{ dropdown: s.dropDown, container: `mx-1 ${className}`, wrapper: s.wrapper }}
      isContentToggle={false}
      container={dropDownContainer}
      hideOnLocationChange
      anchorOrigin={{ horizontal: 'center' }}
      style={{ color: 'inherit' }}
    >
      <Box
        sx={{
          position: 'relative',
          padding: '2rem 0.5rem 2rem 3rem',
          maxWidth: '100vw',
          fontSize: '1rem',
          lineHeight: '1.5rem',
          height: '100%',
          '@media (min-width: 768px)': {
            minWidth: '735px',
          },
        }}
      >
        <h2 className={s.title}>{title}</h2>
        <ul className={s.packageList}>
          {orderPackages(packagesToShow).map((packageItem) => (
            <PackageItem
              key={packageItem.marketingName + packageItem.packageId}
              packageItem={packageItem}
              {...packageItemProps}
            />
          ))}
        </ul>
        {showAddButton && <Button onClick={() => history.push(managePackageLink)}>{addSubscriptionLabel}</Button>}
      </Box>
    </Dropdown>
  );
};

export default SubscriptionInformation;
