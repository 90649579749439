import React, { useMemo } from 'react';
import { ManagePaymentContainer, ManagePaymentProps } from './ManagePayment';
import { PaymentContext } from './PaymentContext';
import { COUNTRY_DETAIL_LOOKUP, SubmitCallback, SubscriptionInfo } from '../../Types';
import { CONFIG_KEYS, SubscriptionProps, WebContent } from '@manageSubscription';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { PayPalScriptOptions } from '@paypal/paypal-js';
import { useGetCombinedPaymentMethods } from '@app/components-lib/services/cps/queries';
import useAccount from '@api/queries/useAccount';
import Loader from '@components/Loader';

export type PaymentContextProviderProps = {
  navigateCancel?: () => void;
  submitCallback: SubmitCallback;
  subscriptionInfo?: SubscriptionInfo;
  send?: Function;
  clientIP?: string;
  managePage?: boolean;
  children?: React.ReactNode;
  content: WebContent;
  subscriptionProps: SubscriptionProps;
  notifyOnFormOpen?: (isOpen: boolean) => void;
} & ManagePaymentProps;

export const PaymentContextProvider: React.FC<PaymentContextProviderProps> = ({
  submitCallback,
  content,
  subscriptionProps,
  subscriptionInfo,
  send,
  managePage = false,
  children,
  hidePaymentButton,
  notifyOnFormOpen = () => {},
}) => {
  const { data: paymentMethods } = useGetCombinedPaymentMethods(subscriptionProps);

  const hasNoPaymentMethods = paymentMethods.length === 0;
  const isFirstPaymentMethod = managePage && hasNoPaymentMethods;

  const paymentProps = {
    subscriptionInfo,
    submitCallback,
    content,
    subscriptionProps,
    send,
    payPalClientId: undefined,
    hidePaymentButton,
    notifyOnFormOpen,
    isFirstPaymentMethod,
  };

  const { config } = subscriptionProps;
  const payPalClientId = config.get(CONFIG_KEYS.PAYPAL_CLIENT_ID);
  paymentProps.payPalClientId = payPalClientId;

  const { data: account, isFetching } = useAccount();
  const accountCountry = account?._id ? account.mailingCountry : 'USA';
  const SCRIPT_PROVIDER_OPTIONS = useMemo<PayPalScriptOptions>(() => {
    const { currencyCode } = COUNTRY_DETAIL_LOOKUP[accountCountry];

    return {
      'client-id': payPalClientId,
      'disable-funding': 'credit,card',
      currency: currencyCode,
      vault: true,
      intent: 'tokenize',
    };
  }, [accountCountry, payPalClientId]);

  let paymentComponent = (
    <ManagePaymentContainer managePage={managePage} hidePaymentButton={hidePaymentButton}>
      {children}
    </ManagePaymentContainer>
  );

  if (payPalClientId) {
    paymentComponent = (
      <PayPalScriptProvider options={SCRIPT_PROVIDER_OPTIONS}>{paymentComponent}</PayPalScriptProvider>
    );
  }

  if (isFetching) {
    return <Loader />;
  }
  return <PaymentContext.Provider value={paymentProps}>{paymentComponent}</PaymentContext.Provider>;
};
