import React from 'react';
import ManagePaidPackagesContainer from './ManagePaidPackagesContainer';
import SelectPaidPackages from './SelectPaidPackages';
import { useFeatureFlags } from '@components/FeatureFlags';

const ManagePaidPackagesContainerFeatureFlag = () => {
  const { newDesignPackageSelectionEnabled } = useFeatureFlags();
  return newDesignPackageSelectionEnabled ? <SelectPaidPackages /> : <ManagePaidPackagesContainer />;
};

export default ManagePaidPackagesContainerFeatureFlag;
