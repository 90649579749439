import React, { useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import { useFlowMachineContextFlowStorage } from '@app/components-lib/Router/RouterContext';

const PackagesSelect = () => {
  const [selectedPackage, setSelectedPackage] = useState('');
  const { eligiblePackages } = useFlowMachineContextFlowStorage();

  return (
    <Select
      value={selectedPackage}
      onChange={(event) => setSelectedPackage(event.target.value)}
      displayEmpty
      inputProps={{ 'aria-label': 'Without label' }}
      fullWidth
      sx={(theme) => ({
        textAlign: 'center',
        marginBottom: '1.5rem',
        backgroundColor: theme.palette.common.white,
      })}
      MenuProps={{
        disableScrollLock: true,
      }}
    >
      <MenuItem value="">Select a package here</MenuItem>
      {eligiblePackages.map((pkg) => (
        <MenuItem value={pkg.marketingName} key={pkg.marketingName}>
          {pkg.marketingName}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PackagesSelect;
