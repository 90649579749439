import React from 'react';
import { Stack, Typography } from '@mui/material';
import Button from '@app/components-lib/components/Button';

export type SelectedTotalProps = {
  onContinue: () => void;
};

const SelectedTotal = ({ onContinue }: SelectedTotalProps) => {
  return (
    <Stack
      width="100%"
      mb="1rem"
      sx={{
        marginTop: '1rem',
        '@media (min-width: 1200px)': {
          marginTop: '5.5rem',
        },
      }}
    >
      <Stack direction="row" justifyContent="space-between" marginBottom="0.75rem">
        <Typography>No items selected</Typography>
        <Stack alignItems="end">
          <Typography fontSize="1.2rem" fontWeight={700} lineHeight="1.2rem">
            $0
          </Typography>
          <Typography
            fontSize="0.8rem"
            fontWeight={700}
            lineHeight="0.8rem"
            sx={(theme) => ({ color: theme.palette.grey[600] })}
          >
            +taxes apply
          </Typography>
        </Stack>
      </Stack>
      <Button onClick={onContinue}>Continue</Button>
    </Stack>
  );
};

export default SelectedTotal;
